]<!-- =========================================================================================
    File Name: Faq.vue
    Description: FAQ Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="faq-page">
        <!-- JUMBOTRON -->
        <div class="faq-jumbotron">
            <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Sorularınız mı Var?</h1>
                <p class="text-white">Programlarımız ve Hizmetlerimiz Hakkında bilgi edinmek için Soru ve Cevaplara Birkaç dakikanızı ayırın...</p>
                <vs-input placeholder="Search" v-model="faqSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6" icon-no-border />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
                <vx-card>
                    <h4>Kategori</h4>
                    <ul class="faq-topics mt-4">
                        <li v-for="category in categories" :key="category.id" class="p-2 font-medium flex items-center" @click="faqFilter = category.id">
                            <div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + category.color"></div><span class="cursor-pointer">{{ category.name }}</span>
                        </li>
                    </ul>

                    <br><br>

                    <h4>Personellerimiz</h4>
                    <ul class="faq-supporters mt-4">
                        <li v-for="supporter in supporters" :key="supporter.id" class="mt-4">
                            <div class="flex items-center">
                                <vs-avatar class="mr-3" :src="require(`@/assets/images/portrait/small/${supporter.img}`)" size="35px" />
                                <div class="leading-tight">
                                    <p class="font-semibold">{{ supporter.name }}</p>
                                    <small>{{ supporter.profession }}</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </vx-card>
            </div>

            <!-- FAQ COL -->
            <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item v-for="(que,index) in filteredFaq" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="que.id">
                        <div slot="header"><h5>{{ que.question }}</h5></div>
                        <p>{{ que.ans }}</p>
                    </vs-collapse-item>
                </vs-collapse>
                
            </div>
        </div>
    </div>
</template>

<script>

export default{
    data() {
        return {
            faqSearchQuery: '',
            faqFilter: 1,
            categories: [
                {
                    id: 1,
                    name: 'Tümü',
                    color: 'grey',
                },
                {
                    id: 2,
                    name: 'Genel',
                    color: 'primary'
                },
                {
                    id: 3,
                    name: 'Lisans',
                    color: 'success'
                },
                {
                    id: 4,
                    name: 'Kurulum',
                    color: 'warning'
                },
                {
                    id: 5,
                    name: 'Eğitim Destek',
                    color: 'danger'
                },
            ],
            faqs: [
                {
                    id: 1,
                    categoryId: 2,
                    question: 'Takılıp kalan ve sürekli döngüye giren (indirip hata veriyor ve tekrar indirmeye çalışıyor gibi) güncellemeler',
                    ans: 'Windows 10’un Home sürümü güncellemeleri zorla yüklemektedir; ancak bundan daha sorun olabilecek durum: Bazen güncellemelerden biri yüklenmez bu durumda güncellemelerin tümü tıkandığından, güncelleme dosyalarını temizlemek gerekebilmektedir. Bunun için C:/Windows/SoftwareDistribution/Download klasörüne gidip klasörün içindeki her şey silinmelidir (klasörün kendisi silinmeyecektir). Bazı dosyaları silmek için Windows’u güvenli modda açmanız gerekebilir.'
                },
                {
                    id: 2,
                    categoryId: 2,
                    question: 'Çok çabuk tükenen pil ve zor şarj olma durumu',
                    ans: 'Cortana ve Windows Update, Windows 10’lu cihazların pilini normalden hızlı tükenmesine neden olan işlevler arasındadır. Bunun için Hey Cortana işlevini ve Windows Update’i başkalarıyla paylaşma işlevini kapatmak denenebilir. (Güncelleme ve Güvenlik > Windows Update > Gelişmiş seçenekler > Güncellemelerin altından nasıl dağıtıldığı seçilecektir)'
                },
                {
                    id: 3,
                    categoryId: 2,
                    question: 'Değişen varsayılan uygulamaların çıkardığı sorunlar',
                    ans: 'Windows 10’a güncellenen ve web bağlantıları veya fotoğraflar istenildiği programla açılmamaktaysa varsayılan uygulamaları değiştirmek gerekmektedir. Windows + I tuşlarına basıp ve Sistem seçilir. Sol tarafta Varsayılan Uygulamalar seçilir. Burada fotoğraflar, videolar ve fazlası için varsayılan uygulamalar belirlenebilmektedir.'
                },
                {
                    id: 4,
                    categoryId: 2,
                    question: 'TouchPad’ in çalışmaması, hiç tepki vermemesi',
                    ans: 'TouchPad çalışmıyorsa, önce devre dışı olup olmadığına bakılmalıdır. Bunun için Windows + I tuşuna basılır ve Cihazlar > Fare ve Touchpad > Ek fare seçenekleri yolunu izlenmelidir. Burada Cihaz Ayarları sekmesine tıklanılır. Cihazlar altında Touchpad’i seçin ve devre dışı bırakılmadığına dikkat edin. Devre dışı ise Etkinleştir düğmesine basılmalıdır \
                    Bu işlem işe yaramazsa Windows + X tuşlarına basılır ve Aygıt Yöneticisi seçilir. Buradan Fare ve diğer işaretleme aygıtları genişletilir ve Touchpad’e sağ tıklanarak Aygıt Sürücüsünü Güncelle seçeneğine tıklanılır. İsteğe bağlı olarak da sürücüler üreticilerin web sitesinden de indirilebilinir.'
                },
                {
                    id: 5,
                    categoryId: 3,
                    question: 'Windows Mağazası uygulamalarının yüklenmemesi ve hata vermesi',
                    ans: 'Windows Mağazası’ndaki uygulamaları yükleyemiyor ise, önbelleği temizlemek sorunu çözebilir. Bunun için Windows + R tuşlarına basılır ve WSReset.exe yazarak Enter’a basılmalıdır. Boş bir komut satırı penceresi açılacaktır; kapanması ve Windows Mağazası’nın açılması beklenmelidir.'
                },
            ],
            supporters: [
                {
                    id: 1,
                    img: 'avatar-s-1.png',
                    name: 'Asım BERBER',
                    profession: 'Genel'
                },
                {
                    id: 2,
                    img: 'avatar-s-3.png',
                    name: 'Recep ATABAY',
                    profession: 'Satış Pazarlama'
                },
                {
                    id: 3,
                    img: 'avatar-s-21.png',
                    name: 'Musa KARAARSLAN',
                    profession: 'Eğitim Destek'
                },
                {
                    id: 4,
                    img: 'avatar-s-15.png',
                    name: 'Arif BERBER',
                    profession: 'Teknik Destek'
                },
                {
                    id: 5,
                    img: 'avatar-s-23.png',
                    name: 'Kadir ÇAKIR',
                    profession: 'Yazılım Geliştirme'
                },
            ]
        }
    },
    computed: {
        filteredFaq() { 
            return this.faqs.filter((faq) => {
                if (this.faqFilter == 1) return (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 2) return faq.categoryId == 2 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 3) return faq.categoryId == 3 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 4) return faq.categoryId == 4 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 5) return faq.categoryId == 5 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
            });
        }
    },
    methods: {
    },
    components: {
    }
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-image: url('../../assets/images/pages/faq.jpg');
        background-size: cover;
    }

    .faq-bg {
        background-color: #fff;
    }   
}
</style>